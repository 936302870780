import React, { useState } from "react";
import {Toast as ToastComponent, ToastContainer} from "react-bootstrap";

interface ToastCreationProps {
    title: string;
    text: string;
}

type Toast = ToastCreationProps & { id: number; }

const ToastDispatchContext = React.createContext<{ add: (toast: ToastCreationProps) => void }>({
    add: () => {
        throw new Error("ToastDispatchContext context is undefined, please verify you are calling useToasts() as child of a <ToastProvider> component.")
    }
});

export const useToasts = () => React.useContext(ToastDispatchContext);

/**
 * Provides toasts, small temporary notifications.
 * To generate a new message, call the useToast() hook:
 *  const toasts = useToasts();
 *  // then in some effect:
 *  toast.add({ title: "Hint", text: "Use toast to inform your user about something"})
 */
export const ToastProvider = ({children}: { children: React.ReactNode }) => {
    const [maxId, setMaxId] = useState<number>(0);
    const [toasts, setToasts] = useState<Toast[]>([]);

    const addToast = (toast: ToastCreationProps) => {
        setToasts(toasts => ([{...toast, id: maxId }, ...toasts]));
        setMaxId(id => id + 1);
    }

    const removeToast = (toastIdToRemove: number) => {
        setToasts(toasts.filter(t => t.id !== toastIdToRemove));
    }

    return (
        <div>
            <ToastDispatchContext.Provider value={{add: addToast}}>
                {children}
            </ToastDispatchContext.Provider>
            <ToastContainer
                className="p-3"
                position="bottom-end"
                style={{ zIndex: 1 }}
            >
                {toasts.map(toast => (
                    <ToastComponent key={toast.id} onClose={() => removeToast(toast.id)} delay={3000} autohide>
                        <ToastComponent.Header>{toast.text}</ToastComponent.Header>
                        <ToastComponent.Body>{toast.title}</ToastComponent.Body>
                    </ToastComponent>
                ))}
            </ToastContainer>

        </div>
    );
}