import React from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Layout} from "./layout/Layout";
import {Reports} from "./reports/Reports";

export default function App() {
    return (
        <Layout>
            <Reports/>
        </Layout>
    )
}
