/**
 * Downloads a file from the given url with Bearer token authorization.
 * Files behind token authorization cannot simply be opened in a new tab
 * since the token cannot be passed in the href, thus this serves as a
 * workaround.
 * @param url location of the file to download
 * @param fileName name assigned to file once downloaded, visible to the user
 * @param token access token for the URL
 */
export const downloadFile = async (url: string, fileName: string, token: String): Promise<void> => {
    const response = await fetch(url, {

        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    if(!response.ok) {
        throw new Error(`Request failed: ${response.statusText}`)
    }

    const blob = await response.blob();
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
}