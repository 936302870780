import React, {useEffect} from "react";
import {useAuth} from "react-oidc-context";
import {Alert, Button, Container} from "react-bootstrap";

/**
 * Ensures the user is logged in before showing the passed content.
 * A login form is shown if the automatic login done by this component did not start
 * or failed for some reason.
 */
export function AuthGuard({children}: { children: React.ReactNode }) {
    const auth = useAuth();

    // Imperatively force login
    // instead of having the user click on the login button
    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
            auth.signinRedirect()
        }
    });

    if (auth.isAuthenticated) {
        return (
            <div>
                {children}
            </div>);
    }

    return (
        <Container>
            {auth.error && <Alert variant="warning">
                Login failed: {auth.error.message} <br/>
                Press <strong>Log in</strong> to try again
            </Alert>}
            <div className="text-center p-1">
                <Button onClick={() => auth.signinRedirect()}>Log in</Button>
            </div>
        </Container>
    );
}