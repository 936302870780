import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from 'react-oidc-context';
import { ToastProvider } from './shared/Toasts';
import './shared/i18n';
import {OIDC_CONFIG} from "./shared/auth";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>

        <AuthProvider {...OIDC_CONFIG}>
            <ToastProvider>
                <App/>
            </ToastProvider>
        </AuthProvider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
