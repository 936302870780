import {AuthProviderProps} from "react-oidc-context";

const inferKeycloakLocationFromOrigin = () => {
    if(window.location.hostname === 'webresults-test.dosilab.ch') {
        return `https://sso-test.dosilab.ch`;
    }
    if(window.location.hostname === 'dosiresults.dosilab.fr') {
        return `https://sso.dosilab.fr`;
    }

    return `http://${window.location.hostname}:9080`
}


export const OIDC_CONFIG: AuthProviderProps = {
    authority: `${inferKeycloakLocationFromOrigin()}/realms/dosilab`,
    client_id: "webresults-frontend",
    redirect_uri: `${window.location.origin}/login`,
    onSigninCallback: (): void => {
        window.history.replaceState({}, "", window.location.pathname)
    }
};

export const PASSWORD_CHANGE_URL = `${OIDC_CONFIG.authority}/protocol/openid-connect/auth?client_id=${OIDC_CONFIG.client_id}&redirect_uri=${OIDC_CONFIG.redirect_uri}&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD`;